import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Footer from '../components/Footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Footer;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://design.systems/coalition"
        }}>{`DSC`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/nyc_dsc"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.flickr.com/people/158537588@N02/"
        }}>{`Flickr`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UC0qWdJQb-hCUVp4ZfbPuPUQ"
        }}>{`YouTube`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://publication.design.systems/"
        }}>{`Medium`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/design-systems-coalition-NYC/meetup"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://designsystems.herokuapp.com/"
        }}>{`Slack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/code-of-conduct"
        }}>{`Code of Conduct`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/styleguide"
        }}>{`Style Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/",
          "title": "button"
        }}>{`Join us on Meetup`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      